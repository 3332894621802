import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'points_of_interest_health';
const namespace = 'ui-pdp-icon ui-pdp-icon--poi-health';

const IconHealth = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHealth.propTypes = {
  className: string,
};

IconHealth.defaultProps = {
  className: null,
};

IconHealth.ICON_ID = ICON_ID;

export default React.memo(IconHealth);
export { IconHealth };
